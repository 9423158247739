<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="tree"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
				row-key="id"
				:tree-props="{children: 'children'}"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="排序" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.sort }}
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		         <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		           <el-form-item label="上级分类" prop="pid">
		                <el-select v-model="form.pid" placeholder="请选择上级分类" size="medium" class="w-100">
		                  <el-option label="顶级分类" :value="0"></el-option>
		                  <el-option v-for="(item,index) in list" :key="index"
		                    :label="item | tree"
		                    :value="item.id">
		                  </el-option>
		                </el-select>
		           	</el-form-item>
		           <el-form-item label="名称" prop="name">
		             <el-input v-model="form.name" placeholder="请输入名称" type="text" clearable />
		           </el-form-item>
		           <el-form-item label="排序" prop="sort">
		             <el-input v-model="form.sort" placeholder="请输入排序" type="number" clearable />
		           </el-form-item>
		         </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'article_type',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				tree:[],
				form:{
					pid:0,
					name:'',
					sort:100,
				},
				rules:{
					name: [
					  { required: true, message: '请输入分类名称', trigger: 'blur' }
					],
				},
			}
		},
		mounted() {
			
		},
		methods:{
		    getList() {
				this.loading = true
				this.axios.get(`/manage/${this.preUrl}/list`, {
				  params: this.queryForm
				}).then(res => {
				  this.total = res.total
				  this.list = res.data
				  this.tree = res.tree
				  this.loading = false
				}).catch(err => {
				  this.loading = false
				})
		    },
			reset() {
			  this.form = {
			   pid:0,
			   name:'',
			   sort:100,
			  }
			  this.resetForm('form')
			},
		},
		filters: {
		  tree(item) {
			if (item.level == 0) {
			  return item.name
			}
			let str = ''
			for (let i = 0; i < item.level; i++) {
			  str += i == 0 ? '|--' : '--'
			}
			return str + ' ' +item.name;
		  }
		},
	}
</script>

<style>
</style>